import React, { useRef } from "react";
import "./Commodity.scss";
import img from "../../assets/sweet-bonanza-free-spins.jpeg";
import img1 from "../../assets/sweet-bonanza-big-win.jpeg";

const Commodity = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);
  const ref6 = useRef(null);
  const ref7 = useRef(null);
  const ref8 = useRef(null);
  const ref9 = useRef(null);
  const ref10 = useRef(null);
  const ref11 = useRef(null);
  const ref12 = useRef(null);
  const ref13 = useRef(null);
  const ref14 = useRef(null);
  const ref15 = useRef(null);
  const ref16 = useRef(null);
  const ref17 = useRef(null);
  const ref18 = useRef(null);
  const ref19 = useRef(null);
  const ref20 = useRef(null);
  const ref21 = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="commodity">
      <div className="commodity-container">
        <div className="commodity-guidebook">
          <p>Content:</p>
          <ul className="tableNavi">
            <li onClick={() => handleClick(ref1)}>Sweet Bonanza Slot Review</li>
            <li onClick={() => handleClick(ref2)}>Design and graphics</li>
            <li onClick={() => handleClick(ref3)}>Gameplay</li>
            <li onClick={() => handleClick(ref4)}>Payment table </li>
            <li onClick={() => handleClick(ref5)}>Features of the game </li>
            <li onClick={() => handleClick(ref6)}>The usual game</li>
            <li onClick={() => handleClick(ref7)}>The bonus game </li>
            <li onClick={() => handleClick(ref8)}>Play Sweet Bonanza</li>
            <li onClick={() => handleClick(ref9)}>Playing for money</li>
            <li onClick={() => handleClick(ref10)}>Free game</li>
            <li onClick={() => handleClick(ref11)}>Player Reviews</li>
            <li onClick={() => handleClick(ref12)}>Conclusion</li>
            <li onClick={() => handleClick(ref13)}>
              Advantages and disadvantages
            </li>
            <li onClick={() => handleClick(ref14)}>Advantages</li>
            <li onClick={() => handleClick(ref15)}>Disadvantages</li>
            <li onClick={() => handleClick(ref16)}>FAQ</li>
            <li onClick={() => handleClick(ref17)}>
              How to get the maximum winnings
            </li>
            <li onClick={() => handleClick(ref18)}>
              In which industry is the Sweet Bonanza slot represented
            </li>
            <li onClick={() => handleClick(ref19)}>
              How to check the honesty of the slot game
            </li>
            <li onClick={() => handleClick(ref20)}>
              Is Sweet Bonanza suitable for beginners
            </li>
            <li onClick={() => handleClick(ref21)}>
              Where can I find the official website of the slot
            </li>
          </ul>
        </div>
        <h2 ref={ref1}>Sweet Bonanza Slot Review</h2>
        <div className="table">
          <div>
            <p>Genre</p>
            <p>Fruit Slot</p>
          </div>
          <div>
            <p>Type</p>
            <p>Video slot</p>
          </div>
          <div>
            <p>Number of reels</p>
            <p>5</p>
          </div>
          <div>
            <p>Number of lines</p>
            <p>No</p>
          </div>
          <div>
            <p>RTP</p>
            <p>96.51%</p>
          </div>
          <div>
            <p>Maximum win</p>
            <p>21,000 credits</p>
          </div>
          <div>
            <p>Minimum bet</p>
            <p> 0.2 credits</p>
          </div>
          <div>
            <p>Maximum bet</p>
            <p>100 credits</p>
          </div>
        </div>
        <div className="pragmatic-play">
          <p>
            Sweet Bonanza is a classic fruit slot with a modern design and
            exciting gameplay. The main advantage of this game is the high RTP,
            which allows you to make a profit more often. And the cascade
            mechanism gives you a chance to win big in one spin. Let's learn
            more let's look at the features of this slot in our review.
          </p>
          <div className="pragmatic-play-img">
            <img src={img} alt="img" />
            <p>Get free spins in the Sweet Bonanza slot</p>
          </div>
        </div>
        <div className="gates-of-olympus" ref={ref2}>
          <h2>Design and graphics</h2>
          <p>
            Visually, the slot is designed in a bright fruit theme. Playing
            field it is decorated in warm shades - from yellow to red. Symbols
            they are made in the form of delicious fruits - grapes, watermelon,
            lemon and other. The background of the game is decorated with
            fireworks. In general, the graphics are at a high level It allows
            you to fully immerse yourself in the atmosphere of the holiday.
          </p>
          <h3 ref={ref3}>Gameplay</h3>
          <p>
            The gameplay in the slot is quite simple. The game uses a cascading
            mechanism for forming winning combinations. After the launch The
            reels, the winning symbols that fall out explode, giving way new
            ones that can also form a profitable line.
          </p>
          <p>
            Thus, in one rotation, you can get a series of consecutive wins. The
            more matches, the higher multiplier. So in Sweet Bonanza you can
            easily get x100 bets and more than one spin.
          </p>
          <p>
            There are 10 main symbols in the game - various fruits. The most The
            most expensive of them are Grapes, Pineapple and Watermelon.
          </p>
          <p>
            There is also a special scatter symbol in the form of a lollipop in
            the slot, which triggers bonus free spins.
          </p>
          <h3 ref={ref4}>Payment table</h3>
          <div className="table">
            <div>
              <p>Symbol</p>
              <p>Payment</p>
            </div>
            <div>
              <p>Grape</p>
              <p>60х</p>
            </div>
            <div>
              <p>Pinaple</p>
              <p>25x</p>
            </div>
            <div>
              <p>Watermelon</p>
              <p>20x</p>
            </div>
            <div>
              <p>Plum</p>
              <p>10x</p>
            </div>
            <div>
              <p>Lemon</p>
              <p>5x</p>
            </div>
            <div>
              <p>Strawberry</p>
              <p>4x</p>
            </div>
            <div>
              <p>Cherry</p>
              <p>2x</p>
            </div>
            <div>
              <p>Orange</p>
              <p>1x</p>
            </div>
            <div>
              <p>Coin</p>
              <p>1x</p>
            </div>
            <div>
              <p>Star</p>
              <p>1x</p>
            </div>
          </div>
          <p>
            As you can see from the paytable, the most profitable symbols in the
            slot are Grapes, Pineapple and Watermelon. They are the ones who
            most often form big wins in the game.
          </p>
        </div>
        <div className="gates-of-olympus" ref={ref5}>
          <h2>Features of the game</h2>
          <p>
            The main features of Sweet Bonanza slot are cascading the mechanism
            and bonus spins with an increasing multiplier. Let's Let's take a
            closer look at these functions.
          </p>
          <h3 ref={ref6}>The usual game</h3>
          <p>
            The cascade mechanism is used in the main mode. When falling out
            After a winning combination, the symbols explode and their place is
            taken by new. Thus, in one spin you can get a series consecutive
            wins with an increasing multiplier of x2, x3, x5.
          </p>
          <p>
            The longer the chain, the higher the final multiplier. Thus, large
            winnings in hundreds and thousands of bets regularly fall out in the
            slot.
          </p>
          <h3 ref={ref7}>The bonus game</h3>
          <p>
            Three or more scattered lollipop symbols trigger the bonus a game
            with 8 free spins. Each subsequent loss of lollipops increases the
            multiplier by 1.
          </p>
          <p>
            Thus, due to cascades, you can wind up a multiplier to x100 or more.
            This gives you a chance to hit the jackpot of 21,000 credits. Bonus
            spins can be restarted indefinitely, so the potential for The large
            winnings are very high.
          </p>
          <div className="pragmatic-play-img">
            <img src={img1} alt="img" />
            <p>Play Sweet Bonanza slot for free</p>
          </div>
        </div>
        <div className="gates-of-olympus" ref={ref8}>
          <h3>Play Sweet Bonanza</h3>
          <p>
            You can play Sweet Bonanza slot at the best online casinos. This
            slot machine is featured on many gaming sites such as:
          </p>
          <ul className="tableNavi">
            <li>JoyCasino</li>
            <li>Casino-X</li>
            <li>Vulcan</li>
          </ul>
          <p>
            A demo version of the slot is also available without registration,
            where you can try out the game for free. This will allow beginners
            to practice before playing for real money.
          </p>
          <h3 ref={ref9}>Playing for money</h3>
          <p>
            Sweet Bonanza slot allows you to play both on conditional chips and
            on real money. The minimum bet in the game is 0.2 credits, The
            maximum is up to 100 credits per spin. This way, players can choose
            the appropriate bet size for your budget.
          </p>
          <p>
            The variance (volatility) in the slot is above average. This means
            that The game often yields large but rare winnings. Therefore, for
            stable The payments will require a large bankroll. But there is a
            chance to win A jackpot of 21,000 credits and get a good profit.
          </p>
          <h3 ref={ref10}>Free game</h3>
          <p>
            To get acquainted with the game and practice the strategy, there is
            a demo the Sweet Bonanza version. It completely repeats the original
            with the only the difference is that the game is played on virtual
            chips.
          </p>
          <p>
            The free version allows you to try out all the functions of the
            slot, explore payouts and game features. It's a great way to
            practice before playing for money.
          </p>
          <h3 ref={ref11}>Player Reviews</h3>
          <p>
            In general, Sweet Bonanza slot gets high marks among the players:
          </p>
          <ul className="tableNavi">
            <li>Good RTP 96%</li>
            <li>Frequent bonus drops</li>
            <li>Big wins due to cascades</li>
            <li>Colorful themes and graphics</li>
          </ul>
          <p>But there are also disadvantages:</p>
          <ul className="tableNavi">
            <li>High volatility</li>
            <li>You need a big pot to play</li>
            <li>A lot of "empty" spins without winning</li>
          </ul>
          <p>
            However, most of the reviews are positive. The players note frequent
            payouts and good potential for winnings. The slot allows you to make
            long series of cascades and receive payouts of x10000 bets and
            higher.
          </p>

          <h3 ref={ref12}>Conclusion</h3>
          <p>
            Sweet Bonanza is a high-quality modern slot with original gameplay
            and high RTP. The game has an attractive the theme, high-quality
            graphics and sound. Unique the cascade mechanism in combination with
            bonus free spins gives you a chance to getting big winnings.
          </p>
          <p>The main advantages of the Sweet Bonanza slot:</p>
          <ul className="tableNavi">
            <li>Good RTP 96%</li>
            <li>Frequent bonus drops</li>
            <li>Potential for big wins</li>
            <li>Colorful themes and graphics</li>
          </ul>
        </div>
        <p>
          One of the disadvantages is high volatility. But for amateurs Thrills
          are more of a plus than a minus.
        </p>
        <p>
          In general, Sweet Bonanza is an excellent choice for both beginners
          and experienced players. Play this slot in the best online casinos and
          win big jackpots!
        </p>
        <div className="gates-of-olympus" ref={ref13}>
          <h2>Advantages and disadvantages</h2>
          <h3 ref={ref14}>Advantages</h3>
          <ul className="tableNavi">
            <li>High RTP 96%</li>
            <li>Frequent bonus drops</li>
            <li>Potential for big wins</li>
            <li>Colorful themes and graphics</li>
            <li>Simple rules</li>
          </ul>
          <h3 ref={ref15}>Disadvantages</h3>
          <ul className="tableNavi">
            <li>High volatility</li>
            <li>A lot of drawdown spins</li>
            <li>Need a big bankroll</li>
          </ul>
          <h2 ref={ref16}>FAQ</h2>
          <h3 ref={ref17}>How to get the maximum winnings</h3>
          <p>
            To get the maximum win x21000 bets in Sweet Bonanza, You need to
            launch the bonus and collect the maximum multiplier x100. This it is
            possible when long chains of identical symbols and frequent ones
            fall out bonus spins. A large bet size is also important.
          </p>
          <h3 ref={ref18}>
            In which industry is the Sweet Bonanza slot represented
          </h3>
          <p>
            Sweet Bonanza is a video slot that refers to the online casino and
            gambling industry. This slot is designed by Pragmatic Play, which is
            a well-known provider virtual slot machines.
          </p>
          <h3 ref={ref19}>How to check the honesty of the slot game</h3>
          <p>
            The honesty of video slots, including Sweet Bonanza, is ensured
            using a random number generator (RNG). The RNG guarantees the random
            result of each spin in the game. Each licensed slot The RNG is being
            tested for compliance with integrity standards.
          </p>
          <h3 ref={ref20}>Is Sweet Bonanza suitable for beginners</h3>
          <p>
            Sweet Bonanza is perfect for beginners in the world of online
            gambling. The slot has simple rules, addictive gameplay and colorful
            graphics. subject matter. Moreover, a demo version is available,
            where you can try out all the functions.
          </p>
          <h3 ref={ref21}>Where can I find the official website of the slot</h3>
          <p>
            You can get acquainted with the official information about Sweet
            Bonanza slot and play in demo mode on the website of the developer
            Pragmatic Play. The slot is also presented on the websites of
            popular online casinos.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Commodity;
