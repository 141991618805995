import React, { useRef } from "react";
import img from "../../../assets/sweet-bonanza-igrat-na-dengi.jpeg";
import img1 from "../../../assets/SweetDown.jpeg";

const Commodity = () => {
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const handleClick = (ref) => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="commodity">
      <div className="commodity-container">
        <div className="commodity-guidebook">
          <p>Content:</p>
          <ul className="tableNavi">
            <li onClick={() => handleClick(ref1)}>
              The possibilities of playing Sweet Bonanza on different devices{" "}  
            </li>
            <li onClick={() => handleClick(ref2)}>
              Detailed instructions for downloading and installing Sweet Bonanza
            </li>
            <li onClick={() => handleClick(ref3)}>
              Additional tips and recommendations
            </li>
            <li onClick={() => handleClick(ref4)}>Conclusion </li>
          </ul>
        </div>

        <h2>
          The possibilities of playing Sweet Bonanza on different devices{" "}
        </h2>
        <p>
          Sweet Bonanza slot can be played directly in the browser on a desktop
          PC or laptop without installing additional software. But there is also
          a mobile application for Android and iOS, which opens up additional
          possibilities:
        </p>
        <ul className="tableNavi">
          <li>Play offline without internet </li>
          <li>Receive notifications and bonuses </li>
          <li>Use touch gestures on smartphones </li>
        </ul>
        <p>
          The application completely repeats the functionality of the desktop
          version and is adapted to different screens. You can enjoy the game on
          the go or in any convenient place. Let's look at the Sweet Bonanza
          installation process in detail.
        </p>
        <div className="pragmatic-play-img">
          <img src={img} alt="img" />
          <p>Play for money in Sweet Bonanza slot</p>
        </div>

        <div className="gates-of-olympus" ref={ref2}>
          <h2>
            Detailed instructions for downloading and installing Sweet Bonanza
          </h2>
          <p>
            To download and install the Sweet Bonanza app, you only need to take
            4 simple steps:
          </p>
          <p>
            Step 1. Prepare your mobile device, turn on the Internet and Wi-Fi.
            Installation will require 130 MB of free space.
          </p>
          <p>
            Step 2. Find the app in your OS's store - the App Store for iOS or
            the Play Market for Android. Enter the query "Sweet Bonanza" and
            select the official app.
          </p>
          <p>
            Step 3. Click the Install button and wait for the download. The
            process will take 1-2 minutes depending on the Internet speed.
          </p>
          <p>Step 4. After installation, click "Open" and enjoy the game!</p>
          <h2 ref={ref3}>Additional tips and recommendations </h2>
          <p>
            Sweet Bonanza supports devices running Android 5.0 and above and iOS
            11 and above. The best option would be iPhone 7 and newer or Android
            8.0 and newer.
          </p>
          <p>
            The game is also well adapted for tablets with a large screen, where
            you can better see the details and characters.
          </p>
          <p>
            When installing from the official stores, you will receive the
            latest version and all relevant bonuses for new users.
          </p>
          <div className="pragmatic-play-img">
            <img src={img1} alt="img" />
            <p>Play Sweet Bonanza slot on PC </p>
          </div>
          <h3 ref={ref4}> Conclusion </h3>
          <p>
            By downloading and installing the Sweet Bonanza app on your
            smartphone or tablet, you can play an exciting slot at any time in a
            place convenient for you. The high-quality mobile version will allow
            you to fully immerse yourself in the gameplay and get a lot of
            positive emotions. Enjoy the excitement!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Commodity;
