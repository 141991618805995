import React from "react";
import { GameIntro } from "../components/gameIntro/GameIntro";
import PlayCards from "../components/playCards/PlayCards";
import Commodity from "../components/commodity/Commodity";
import Promo from "../components/promo/Promo";

const MainPage = () => {
  return (
    <div className="App">
      <GameIntro />
      <PlayCards />
      <Commodity />
      <Promo />
    </div>
  );
};

export default MainPage;
