import React from "react";
import img1 from "../../../assets/b2yfa4.jpg";
import img2 from "../../../assets/1p7rco.jpg";
import img3 from "../../../assets/zmqy59.jpg";

const PlayCards = () => {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">You can download Sweet Bonanza here:</h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">Казино 1WIN</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19 btn-green">Play</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">Казино IZZI</p>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19 btn-green"> Play</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">Казино PinUp</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19 btn-green">Play</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        Sweet Bonanza is an exciting slot machine that can be downloaded to
        various devices. In this article, you will learn how to install the
        Sweet Bonanza app on your computer, smartphone or tablet on Android or
        iOS.
      </p>
    </div>
  );
};
export default PlayCards;
