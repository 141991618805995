import React from "react";
import "./Header.scss";
import headerLogo from "../../assets/bonanza-sweet-header.png";

const Header = () => {
  return (
    <header className="header">
      <div className="header-container">
        <div className="header-logo">
          <a href="/">
            <img src={headerLogo} alt="headerlogo" />
          </a>
        </div>
        <div className="header-content">
          <a href="/">Sweet Bonanza</a>
          <a href="/download">Download</a>
        </div>
      </div>
    </header>
  );
};

export default Header;
