import React from "react";
import "./PlayCards.scss";
import img1 from "../../assets/b2yfa4.jpg";
import img2 from "../../assets/1p7rco.jpg";
import img3 from "../../assets/zmqy59.jpg";

function PlayCards() {
  return (
    <div className="playcard-container">
      <h1 className="playCardsTitle">Where to play Sweet Bonanza</h1>
      <div className="playCardBox">
        <div className="playCardIzzi">
          <img src={img3} alt="img" className="playCardImg"></img>
          <p className="playCardText">1WIN Casino</p>
          <a href="https://1wiaxy.xyz/#79x5" target="_blank">
            <button className="button-19">Play</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img1} alt="img" className="playCardImg"></img>
          <p className="playCardText">IZZI Casino</p>
          <a href="https://izzicasino.life/cf91e09c3" target="_blank">
            <button className="button-19"> Play</button>
          </a>
        </div>
        <div className="playCardIzzi">
          <img src={img2} alt="img" className="playCardImg"></img>
          <p className="playCardText">PinUP Casino</p>

          <a href="https://onlinepuonline.com/cwbBKAX7/" target="_blank">
            <button className="button-19">Play</button>
          </a>
        </div>
      </div>
      <p className="warningText">
        Sweet Bonanza is a popular fruit-themed game. Learn all about the
        gameplay features and winning opportunities. Test the video slot in demo
        mode absolutely for free before you start playing for money.
      </p>
    </div>
  );
}
export default PlayCards;
